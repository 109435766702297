<template>
    <div class="d-flex flex-column flex-root">
        <div
            class="st-auth d-flex flex-column flex-lg-row flex-column-fluid bg-white"
            id="st_auth"
        >
            <!--begin::Aside-->
            <div
                class="st-auth-aside d-flex flex-column flex-row-auto"
                :style="{ backgroundImage: `url(${backgroundImage})` }"
            >
                <div class="d-flex flex-column-auto flex-column st-auth-aside-logo">
                    <a href="#" class="text-center mb-10">
                        <img :src="logoImage" class="max-h-70px" alt=""/>
                    </a>
                </div>
            </div>
            <!--begin::Aside-->
            <!--begin::Content-->
            <div
                class="st-auth-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
            >
                <div class="d-flex justify-content-end align-content-center">
                    <language-select></language-select>
                </div>
                <div class="d-flex flex-column-fluid flex-center">
                    <!--begin::Form-->
                    <transition name="fade-in-up" mode="out-in">
                        <router-view/>
                    </transition>
                    <!--end::Form-->
                </div>
                <!--begin::Content footer-->
                <div
                    class="d-flex justify-content-between align-items-end py-7 px-1 py-lg-0 mb-10"
                >
                    <a href="#" class="text-primary font-weight-bold font-size-h6">{{
                        $t('AUTH.GENERAL.PRIVACY')
                        }}</a>
                    <a href="#" class="text-primary ml-10 font-weight-bold font-size-h6">{{
                        $t('AUTH.GENERAL.LEGAL')
                        }}</a>
                </div>
                <!--end::Content footer-->
            </div>
            <!--end::Content-->
        </div>
    </div>
</template>

<script>
    import LanguageSelect from '../../../shared/i18n/LanguageSelect';

    export default {
        name: 'AuthLayout',
        components: {
            LanguageSelect,
        },
        data() {
            return {
                backgroundImage: `${process.env.BASE_URL}media/st-login-bg.jpg`,
                logoImage: `${process.env.BASE_URL}media/st-logo.svg`
            };
        },
    };
</script>

<style lang="scss">
    @import '@/assets/sass/pages/auth/auth-layout.scss';
</style>
